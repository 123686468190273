import { AccountTableSearchQuery } from '@Types/search.types';
import { AccountTypes } from '@innovyze/shared-utils/lib/types/Account';
import { AxiosResponse } from 'axios';
import { OrgAccount } from '@Types/account.types';
import { RegisteredApplications } from '@innovyze/stylovyze';
import superadmin from '../apis/superadmin.api';

export const getAccountsService = (
	limit: number,
	offset: number,
	query: AccountTableSearchQuery | undefined,
	isAutodeskManagedLibrary: boolean | undefined,
) => {
	const rawQuery = query || {
		organizationName: '',
		organizationId: '',
		teamId: '',
	};
	const params = new URLSearchParams({
		limit: limit.toString(),
		offset: offset.toString(),
		...(isAutodeskManagedLibrary !== undefined && {
			isAutodeskManagedLibrary: isAutodeskManagedLibrary.toString(),
		}),
	});

	const searchQuery = Object.keys(rawQuery)
		.map(r => rawQuery[r] && `${r}:${encodeURI(rawQuery[r])}`)
		.filter(v => !(v == null || v == undefined || v == ''));
	const uri = `/organization-accounts?${params.toString()}${
		searchQuery.length ? '&q=' : ''
	}${searchQuery.join(',')}`;
	return superadmin.get(uri);
};

export const getAccount = (id: string) =>
	superadmin.get(`/organization-account/${id}`);

export const createAccountDetails = (
	accountDetails: Omit<OrgAccount, 'primaryAdmins'>,
): Promise<AxiosResponse<OrgAccount>> =>
	superadmin.put('/organization-account', accountDetails);

export const updateAccountDetails = (
	accountDetails: Partial<OrgAccount>,
): Promise<AxiosResponse<OrgAccount>> => {
	const orgId = accountDetails.organizationId;
	const newAccountDetails = {
		...accountDetails,
		additionalAdmins: accountDetails.additionalAdmins?.reduce(
			(acc, el) => `${acc},${el}`,
			'',
		),
		primaryAdmins: accountDetails?.primaryAdmins?.map(el => ({
			email: el.email || null,
		})),
	};
	delete newAccountDetails.organizationId;
	return superadmin.patch(
		`/organization-account/${orgId}`,
		newAccountDetails,
	);
};

export const updateAccountSingleUserInfo = (
	userDetails: Partial<{
		entitlements: string[];
		organization: string;
		role: string;
		email: string | null;
		name: string | null;
		applicationName?: RegisteredApplications;
		accountType?: AccountTypes;
	}>,
): Promise<AxiosResponse<OrgAccount>> => {
	const { email } = userDetails;

	if (email) return superadmin.put('/user', userDetails);
	return Promise.resolve({
		data: {} as OrgAccount,
		status: 200,
		statusText: 'OK',
		request: {},
		headers: {},
		config: {},
	});
};
