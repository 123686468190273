import { AccountFormDataType } from '../contexts/AccountFormContext';
import React from 'react';
import { User } from '@Types/user.types';
import createAccountUtil from '../utils/createAccountUtil';
import { useDispatch } from 'react-redux';
import useHandleAccountFormEdit from './useHandleAccountFormEdit';
import { useParams } from 'react-router';

export default function useAccountSubmit({
	isValid,
	accountFormData,
	currentAdditionalAdmins,
}: {
	isValid: boolean;
	accountFormData: AccountFormDataType;
	currentAdditionalAdmins?: User[];
}) {
	const { id } = useParams<{ id: string }>();
	const isEdit = !!id;
	const dispatch = useDispatch();

	// eslint-disable-next-line prettier/prettier
	const handleAccountFormCreate: React.MouseEventHandler<HTMLButtonElement> =
	() => {
		if (accountFormData && isValid) {
			const payload = { dispatch, accountFormData };
			createAccountUtil(payload);
		}
	};

	const handleAccountFormEdit = useHandleAccountFormEdit({
		accountFormData,
		isValid,
		currentAdditionalAdmins,
	});

	if (isEdit) return handleAccountFormEdit;
	return handleAccountFormCreate;
}
