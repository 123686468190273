import { AccountFormDataType } from '../contexts/AccountFormContext';
import { Dispatch } from 'redux';
import { OrgAccount } from '@Types/account.types';
import { checkAssetsEntitlements } from '@Utils';
import { createAccount } from '@Actions/accounts';
import { createAccountDetailsRejected } from '@Actions';
import getEntitlementsString from './getEntitlementsString';
import { error as notificationError } from '@innovyze/stylovyze';
interface Attrs {
	accountFormData: AccountFormDataType;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dispatch: Dispatch<any>;
}

export function createAccountUtil({ accountFormData, dispatch }: Attrs) {
	const newEntitlements = accountFormData.entitlements;
	const { createDate, organizationName, teamId } =
		accountFormData.accountDetails || {};

	const { isAutodeskManagedLibrary, allowedLibrariesBySuperAdmin } =
		accountFormData.librariesDetails;

	const entitlementString = getEntitlementsString(newEntitlements);
	if (!checkAssetsEntitlements(entitlementString)) {
		dispatch(createAccountDetailsRejected());
		dispatch(
			notificationError(
				'Assets subscriptions must include at least 1 seat of Full Access.',
			),
		);
		return;
	}
	const newAccountDetails: OrgAccount = {
		...accountFormData.accountDetails,
		organizationName: organizationName || '',
		teamId: teamId || '',
		createDate: createDate || new Date().toISOString(),
		lastModifiedDate: new Date().toISOString(),
		entitlements: entitlementString,
		domain: accountFormData.accountDetails.domain,
		isAutodeskManagedLibrary: isAutodeskManagedLibrary,
		allowedLibrariesBySuperAdmin: allowedLibrariesBySuperAdmin,
	};

	dispatch(
		createAccount({
			details: newAccountDetails,
			entitlements: newEntitlements,
		}),
	);
}

export default createAccountUtil;
